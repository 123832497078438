/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
    .container {
        .faq-list {
            padding: 0;
            list-style: none;

            li {
                border-bottom: 1px solid #eee;
                margin-bottom: 20px;
                padding-bottom: 20px;

                a {
                    display: block;
                    position: relative;
                    font-family: var(--oksijen-turuncu);
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    padding-right: 25px;
                    cursor: pointer;

                    &.collapsed {
                        color: rgba(52, 58, 64, 1);

                        &:hover {
                            color: var(--oksijen-turuncu);
                        }

                        .icon-show {
                            display: inline-block;
                        }

                        .icon-close {
                            display: none;
                        }
                    }

                    i {
                        font-size: 24px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    .icon-show {
                        display: none;
                    }

                }

                p {
                    margin-bottom: 0;
                    padding: 10px 0 0 0;
                }
            }
        }
    }
}
